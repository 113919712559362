import {
  Directive,
  ChangeDetectorRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  AfterContentInit,
} from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Directive({
  selector: '[permissionGate]',
})
export class PermissionsDirective implements AfterContentInit {
  private hasView = false;
  private isChecked = false;
  private condition = false;

  private hasCasbinPermission = false;

  private permissionObject: string;
  private permissionAction: string;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private sharedService: ServiceShare,
    private changeDetector: ChangeDetectorRef
  ) {}

  @Input() set permissionGate(condition: boolean) {
    this.condition = condition;
    if (this.permissionObject) {
      this.updateView();
    }
  }

  @Input() set attribute(value: string) {
    this.permissionObject = value;
    this.updateView();
  }

  @Input() set action(value: string) {
    this.permissionAction = value;
    this.updateView();
  }

  ngAfterContentInit(): void {
    this.subscribeForPermissions();
  }

  private subscribeForPermissions(): void {
    this.sharedService.EnforcerService.newBeahviorSubject.subscribe((data) => {
      if (data == 'updated_policies') {
        this.sharedService.EnforcerService.enforceAsync(
          this.permissionObject,
          this.permissionAction
        ).subscribe((hasPermission: boolean) => {
          this.hasCasbinPermission = hasPermission;
          this.addViewOrClearView();
          this.changeDetector.detectChanges();
        });
      }
    });
  }

  private addViewOrClearView(): void {
    if (this.hasCasbinPermission && this.condition) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
    this.changeDetector.detectChanges();
  }

  private updateView(): void {
    if (this.condition && !this.hasView) {
      if (this.isChecked) {
        this.addViewOrClearView();
      } else {
        this.checkForPermission();
      }
    } else if (!this.condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private checkForPermission(): void {
    this.sharedService.EnforcerService.enforceAsync(
      this.permissionObject,
      this.permissionAction
    ).subscribe((hasPermission: boolean) => {
      this.hasCasbinPermission = hasPermission;
      this.addViewOrClearView();

      this.isChecked = true;
    });
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Article } from '../models/article.models';
import { ResponseWithMetadata } from '../models/http.models';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  replayObservable: Observable<{ data: Article }> | null;
  articles: Article[];

  constructor(
    private _http: HttpClient,
    private serviceShare: ServiceShare,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.serviceShare.shareSelf('ArticlesService', this);
  }

  getAllArticles(params: any): Observable<ResponseWithMetadata<Article[]>> {
    return this._http.get<ResponseWithMetadata<Article[]>>(`${this.config.apiUrl}/articles/items`, {
      params,
    });
  }

  getArticleByUuid(uuid: string): Observable<{ data: Article }> {
    return this._http.get<{ data: Article }>(`${this.config.apiUrl}/articles/items/uuid/${uuid}`);
  }

  getArticleDomainPolicies(uuid: string): Observable<string[][]> {
    return this._http
      .get<string[][]>(`${this.config.apiUrl}/articles/items/uuid/${uuid}/policies`)
      .pipe(
        tap((r) => {
          this.serviceShare.hasOwnerCommentsPolicy = JSON.stringify(r).includes(
            'comments(*, isCommentOwner())'
          );
          this.serviceShare.YdocService.checkHiddenCommentsAndUsers();
          return r;
        })
      );
  }

  putArticleById(
    articleId: number,
    name: string,
    oldArticleData: Article
  ): Observable<{ data: Article }> {
    if (oldArticleData.name != name) {
      return this._http.put<{ data: Article }>(
        `${this.config.apiUrl}/articles/items/${articleId}`,
        {
          name,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          updated_at: new Date().toISOString(),
        }
      );
    } else {
      return EMPTY;
    }
  }

  updateArticleUpdatedAt(oldArticleData: Article): Observable<{ data: Article }> {
    oldArticleData.updated_at = new Date().toISOString();
    return this._http.put<{ data: Article }>(
      `${this.config.apiUrl}/articles/items/${oldArticleData.id}`,
      oldArticleData
    );
  }

  deleteArticleById(articleId: number): Observable<HttpResponse<unknown>> {
    return this._http.delete<unknown>(`${this.config.apiUrl}/articles/items/${articleId}`, {
      observe: 'response',
    });
  }

  createArticle(
    name: string,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    layout_id: number
  ): Observable<{
    name: string;
    layout_id: number;
    ydoc_version: number;
  }> {
    return this._http.post<{
      name: string;
      layout_id: number;
      ydoc_version: number;
    }>(`${this.config.apiUrl}/articles/items`, {
      name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      layout_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ydoc_version: this.serviceShare.ydocVersion,
    });
  }
}

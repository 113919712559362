import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscriber, Subscription } from 'rxjs';
import { mainSectionValidations, YdocService } from '../../services/ydoc.service';
import { treeNode } from '../../utils/interfaces/treeNode';
//@ts-ignore
import * as Y from 'yjs';
import {
  articleSection,
  basicArticleSection,
  editorData,
} from '../../utils/interfaces/articleSection';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  checkIfSectionsAreAboveOrAtMax,
  checkIfSectionsAreAboveOrAtMaxAtParentList,
  checkIfSectionsAreAboveOrAtMaxAtParentListWithName,
  checkIfSectionsAreUnderOrAtMin,
  checkIfSectionsAreUnderOrAtMinAtParentList,
  getSectionBasicStructure,
  getFilteredSectionChooseData,
  renderSectionFunc,
} from '@app/editor/utils/articleBasicStructure';
import { FormBuilderService } from '@app/editor/services/form-builder.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { installPatch } from '../cdk-list-recursive/patchCdk';
import { CdkDropList, DropListRef, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  filterFieldsValues,
  parseSecFormIOJSONMenuAndSchemaDefs,
} from '@app/editor/utils/fieldsMenusAndScemasFns';
import { updateYFragment } from '../../../y-prosemirror-src/plugins/sync-plugin.js';
import { DOMParser } from 'prosemirror-model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Transaction } from 'prosemirror-state';
import { LinksData } from '@app/editor/section/taxon-treatments-section/taxon-treatments-section.component';
import { EditorsRefsManagerService } from '@app/layout/pages/library/lib-service/editors-refs-manager.service';

@Injectable({
  providedIn: 'root',
})
export class TreeService implements OnDestroy {
  articleSectionsStructure: basicArticleSection[];
  treeVisibilityChange: Subject<any> = new Subject<any>();
  metadatachangeMap?: Y.Map<any>;
  articleStructureMap?: Y.Map<any>;
  guid?: string;
  toggleTreeDrawer: Subject<any> = new Subject<any>();

  connectedLists: string[] = [];
  sectionFormGroups: { [key: string]: UntypedFormGroup } = {};
  sectionProsemirrorNodes: { [key: string]: string } = {}; // prosemirror nodes as html

  canDropBool: any[] = [true];

  errorSnackbarSubject: Subject<any> = new Subject();

  labelupdateLocalMeta: any = {};

  treeSubsctiption?: Subscription;
  timeout: NodeJS.Timeout;
  obsFunc = (event: any, transaction: any) => {
    let metadatachange = this.metadatachangeMap?.get('change');
    this.serviceShare.ProsemirrorEditorsService.editMode = true;

    if (metadatachange && this.guid != metadatachange?.guid) {
      if (!this.ydocService.editorIsBuild) {
        return;
      }
      if (metadatachange.action == 'listNodeDrag') {
        if (metadatachange.materialData) {
          this.applyNodeDrag(
            metadatachange.from,
            metadatachange.to,
            metadatachange.prevContainerId,
            metadatachange.newContainerId,
            metadatachange.materialData
          );
        } else {
          this.applyNodeDrag(
            metadatachange.from,
            metadatachange.to,
            metadatachange.prevContainerId,
            metadatachange.newContainerId
          );
        }
      } else if (metadatachange.action == 'editNode') {
        if (metadatachange.node && metadatachange.node.title.name == 'Material') {
          this.editMaterial(metadatachange.node, metadatachange.submission);
        } else {
          this.applyEditChange(metadatachange.nodeId);
          this.applyEditChangeV2(metadatachange.nodeId);
        }
      } else if (metadatachange.action == 'addNode') {
        this.attachChildToNode(
          metadatachange.parentId,
          metadatachange.originalSectionTemplate,
          metadatachange.newChild
        );
      } else if (metadatachange.action == 'deleteNode') {
        let { nodeRef, i } = this.deleteNodeById(metadatachange.childId);
      } else if (metadatachange.action == 'addNodeAtPlace') {
        if (metadatachange.newNode instanceof Array) {
          metadatachange.newNode.forEach((section: articleSection) => {
            this.addNodeAtPlace(
              metadatachange.parentContainerID,
              section,
              metadatachange.place,
              section
            );
          });
        } else {
          this.addNodeAtPlace(
            metadatachange.parentContainerID,
            metadatachange.newSection,
            metadatachange.place,
            metadatachange.newNode
          );
        }
      } else if (metadatachange.action == 'replaceChildren') {
        this.replaceChildren(metadatachange.newChildren, metadatachange.parent, true);
      } else if (metadatachange.action == 'buildNewFromGroups') {
        this.buildNewFormGroups(metadatachange.nodes);
      } else if (metadatachange.action == 'saveNewTitle') {
        this.saveNewTitle(metadatachange.node, metadatachange.title);
      } else if (metadatachange.action == 'showHideSection') {
        this.showHideSection(metadatachange.sectionID, metadatachange.value);
      } else if (metadatachange.action == 'importTaxonsChange') {
        this.applyTaxonImportChange(metadatachange.taxonSections, metadatachange.parentContainerID);
      }
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 2000);
  };

  subscribers: { [key: string]: Subscription } = {};

  setTitleListener(section: articleSection) {
    const node = this.findNodeById(section.sectionID);
    if (node) {
      if (this.subscribers[node.sectionID]) {
        this.subscribers[node.sectionID].unsubscribe();
      }
      let formGroup = this.sectionFormGroups[node.sectionID];
      let shouldInterpolate =
        /{{\s*\S*\s*}}|<span(\[innerHTML]="[\S]+"|[^>])+>[^<]*<\/span>/gm.test(
          section.title.template
        );

      if (node.name == 'Taxon') {
        const valuesCopy = {};
        Object.keys(formGroup.value).forEach((key) => {
          valuesCopy[key] = formGroup.value[key];
        });
        const label = this.generateTaxonTitle(valuesCopy).taxonTitle;
        label ? (node.label = label) : (node.label = 'Taxon');
      } else {
        node.label = shouldInterpolate ? node.name! : node.label;
      }
      this.subscribers[node.sectionID] = formGroup.valueChanges.subscribe((data) => {
        if (shouldInterpolate) {
          const values = this.ydocService.customSectionProps.get('customPropsObj')[node.sectionID];
          if (values) {
            values['taxonTitle'] = this.generateTaxonTitle(values).taxonTitle;
            this.serviceShare.ProsemirrorEditorsService?.interpolateTemplate(
              section.title.template,
              values,
              formGroup
            ).then((newTitle: string) => {
              node.label = newTitle;
            });
          }
        } else if (
          formGroup.value.sectionTreeTitle &&
          node.label != formGroup.value.sectionTreeTitle &&
          node.name !== 'Material'
        ) {
          node.label = formGroup.value.sectionTreeTitle;
        }
      });
    }
  }

  resetTreeData() {
    this.articleSectionsStructure = undefined;
    if (this.obsFunc) {
      this.metadatachangeMap?.unobserve(this.obsFunc);
    }
    if (this.treeSubsctiption) {
      this.treeSubsctiption.unsubscribe();
    }
    this.metadatachangeMap = undefined;
    this.articleStructureMap = undefined;
    this.guid = undefined;
    this.sectionFormGroups = {};
    this.sectionProsemirrorNodes = {};
    this.parentListRules = undefined;
  }

  registerConnection(id: string) {
    if (!this.connectedLists.includes(id)) {
      this.connectedLists.push(id);
    }
  }

  connectionChangeSubject: Subject<boolean> = new Subject();
  dropListRefs: { ids: string[]; refs: DropListRef[]; cdkRefs: CdkDropList[] } = {
    ids: [],
    refs: [],
    cdkRefs: [],
  };
  registerDropListRef(ref: DropListRef, cdkDropList: CdkDropList, id: string) {
    if (!this.dropListRefs.ids.includes(id)) {
      this.dropListRefs.ids.push(id);
      this.dropListRefs.cdkRefs.push(cdkDropList);
      this.dropListRefs.refs.push(ref);
      this.connectionChangeSubject.next(true);
    }
  }
  unregisterDropListRef(id: string) {
    if (this.dropListRefs.ids.includes(id)) {
      let index = this.dropListRefs.ids.findIndex((idsearch) => idsearch == id);
      this.dropListRefs.ids.splice(index);
      this.dropListRefs.cdkRefs.splice(index);
      this.dropListRefs.refs.splice(index);
      this.connectionChangeSubject.next(true);
    }
  }
  unregisterConnection(id: string) {
    if (this.connectedLists.includes(id)) {
      this.connectedLists.splice(
        this.connectedLists.findIndex((connId) => connId == id),
        1
      );
    }
  }

  constructor(
    public ydocService: YdocService,
    private formBuilderService: FormBuilderService,
    private serviceShare: ServiceShare,
    private editorsRefsManager: EditorsRefsManagerService,
    private articlesSectionsService: ArticleSectionsService
  ) {
    installPatch(this);

    this.serviceShare.shareSelf('TreeService', this);
    let buildFunc = () => {
      this.guid = this.metadatachangeMap?.doc?.guid;
      this.articleStructureMap = ydocService.getYDoc().getMap('articleStructure');
      this.metadatachangeMap?.observe(this.obsFunc);

      this.treeSubsctiption = this.treeVisibilityChange.subscribe((data) => {
        let guid = this.metadatachangeMap?.doc?.guid;
        this.metadatachangeMap?.set('change', { ...data, guid });
        if (
          !data.materialData &&
          data.node?.title?.name !== 'Material' &&
          !data.rerender &&
          data.action != 'editNode' &&
          data.action != 'buildNewFromGroups' &&
          data.action != 'replaceChildren'
        ) {
          this.setArticleSectionStructureFlat();
        }
      });
      this.setParentListSectionMinMaxRules();
    };
    if (this.ydocService.editorIsBuild) {
      this.metadatachangeMap = ydocService.getYDoc().getMap('editorMetadataChange');
      buildFunc();
    }
    this.ydocService.ydocStateObservable.subscribe(({ event }) => {
      if (event == 'docIsBuild') {
        this.metadatachangeMap = ydocService.getYDoc().getMap('editorMetadataChange');
        buildFunc();
      }
    });
  }

  parentListRules: mainSectionValidations = {};
  setParentListSectionMinMaxRules() {
    this.parentListRules = this.ydocService.articleData.mainSectionValidations;
  }

  ngOnDestroy(): void {
    this.setArticleSectionStructureFlat();
  }

  setArticleSectionStructureFlat(
    materialsSection?: articleSection,
    orderedMaterials?: basicArticleSection[]
  ) {
    let articleSectionsStructureFlat1: string[] = [];
    let makeFlat = (structure: basicArticleSection[]) => {
      structure.forEach((section) => {
        if (section.active) {
          if (
            materialsSection &&
            section.sectionID == materialsSection.sectionID &&
            orderedMaterials
          ) {
            section.children = orderedMaterials;
          }
          articleSectionsStructureFlat1.push(section.sectionID);
        }
        if (section.children.length > 0) {
          makeFlat(section.children);
        }
      });
    };
    makeFlat(this.articleSectionsStructure);

    this.ydocService.articleStructureMap.set(
      'articleSectionsStructureFlat',
      articleSectionsStructureFlat1
    );
    this.ydocService.articleStructureMap.set(
      'articleSectionsStructure',
      this.articleSectionsStructure
    );
  }

  initTreeList(articleSectionsStructure: basicArticleSection[]) {
    this.articleSectionsStructure = articleSectionsStructure;
  }

  getNodeLevel(node: basicArticleSection | articleSection) {
    if (!node) return { nodeLevel: 0, hTag: 0 };
    let nodeLevel: number;
    let hTag = 0;
    let isIn = false;
    const regex = /<h([1-6]).*>/g;
    let findLevel = (children: basicArticleSection[], level: number, h: number) => {
      children.forEach((child, i) => {
        const articleSection = this.ydocService.getSectionByID(child.sectionID);
        if (articleSection) {
          const match = regex.exec(articleSection.prosemirrorHTMLNodesTempl);
          if (child.sectionID == node.sectionID) {
            if (match) {
              hTag = +match[1];
            } else {
              if (level > 0) {
                hTag = h || level + 2;
              } else {
                hTag = 2;
              }
            }
            node.level = hTag;
            nodeLevel = level;
            isIn = true;
          }
          if (
            !isIn &&
            nodeLevel == undefined &&
            articleSection.type == 'complex' &&
            child.children.length > 0
          ) {
            findLevel(child.children, level + 1, articleSection.level + 1);
          }
        }
      });
    };
    findLevel(this.articleSectionsStructure, 0, 0);
    //@ts-ignore
    return { nodeLevel, hTag };
  }

  buildNewFormGroups(nodes: articleSection[]) {
    let buildForms = (node: any) => {
      let dataFromYMap = this.ydocService.sectionFormGroupsStructures!.get(node.sectionID);
      let defaultValues = dataFromYMap ? dataFromYMap.data : node.defaultFormIOValues;
      let sectionContent = defaultValues
        ? this.formBuilderService.populateDefaultValues(
            defaultValues,
            node.formIOSchema,
            node.sectionID,
            node
          )
        : node.formIOSchema;
      let nodeForm: UntypedFormGroup = new UntypedFormGroup({});
      this.formBuilderService.buildFormGroupFromSchema(nodeForm, sectionContent, node);

      nodeForm.patchValue(defaultValues);
      nodeForm.updateValueAndValidity();
      this.sectionFormGroups[node.sectionID] = nodeForm;
      this.setTitleListener(node);
      if (node.children.length > 0) {
        node.children.forEach((child: any) => {
          buildForms(child);
        });
      }
    };

    nodes.forEach((section) => {
      buildForms(section);
    });
  }

  saveNewTitleChange(node: articleSection, title: string) {
    this.saveNewTitle(node, title);
    this.treeVisibilityChange.next({ action: 'saveNewTitle', node, title });
  }

  saveNewTitle(node: articleSection, title: string) {
    let nodeRef = this.ydocService.getSectionByID(node.sectionID);
    if (nodeRef) {
      nodeRef.title.label = title;
    }
  }

  buildNewFormGroupsChange(nodes: articleSection[]) {
    this.buildNewFormGroups(nodes);
    this.treeVisibilityChange.next({ action: 'buildNewFromGroups', nodes });
  }

  replaceChildren(
    newChildren: articleSection[],
    parent: articleSection,
    replaceFromOtherRoot: boolean
  ) {
    let nodeRef = this.ydocService.getSectionByID(parent.sectionID);
    nodeRef!.children = newChildren;
  }

  replaceChildrenChange(newChildren: articleSection[], parent: articleSection) {
    this.replaceChildren(newChildren, parent, false);
    this.treeVisibilityChange.next({ action: 'replaceChildren', newChildren, parent });
  }

  dragNodeChange(
    from: number,
    to: number,
    prevContainerId: string,
    newContainerId: string,
    node: basicArticleSection | articleSection,
    materialData?: { parent: articleSection; children: any[] }
  ) {
    if (materialData) {
      this.treeVisibilityChange.next({
        action: 'listNodeDrag',
        from,
        to,
        prevContainerId,
        newContainerId,
        materialData,
      });
    } else {
      setTimeout(() => {
        this.serviceShare.updateCitableElementsViews();
      }, 10);

      this.updateSectionView(node);
      this.treeVisibilityChange.next({
        action: 'listNodeDrag',
        from,
        to,
        prevContainerId,
        newContainerId,
      });
    }
  }
  updateSectionView(node: basicArticleSection | articleSection) {
    const articleSection = this.ydocService.getSectionByID(node.sectionID);

    let findF = (list: articleSection[], cb: (node: articleSection) => void) => {
      list.forEach((node) => {
        cb(node);
        if (node.children && node.children.length > 0) {
          findF(node.children, cb);
        }
      });
    };

    const updateView = (node: articleSection) => {
      //@ts-ignore
      if (typeof node.name == 'string') node = this.ydocService.getSectionByID(node.sectionID);

      const n = node.title?.name == 'Material' ? node.originalSectionTemplate.parent : node;
      const { hTag } = this.getNodeLevel(n);
      const editor = this.serviceShare.ProsemirrorEditorsService.editorContainers[node.sectionID];
      let htmlTemplate = node.prosemirrorHTMLNodesTempl;
      let sectionFormGroup: any;
      let submission: any;
      let linksPrefix: any;

      if (!editor) return;

      switch (node.title.name) {
        case 'Material': {
          return;
        }
        case '[MM] External Links': {
          linksPrefix = node.formIOSchema.linksPrefix;
          sectionFormGroup = this.sectionFormGroups[node.sectionID];
          submission = sectionFormGroup.value;
          break;
        }
        case '[MM] Materials': {
          const materialsParent = this.findNodeById(node.sectionID);
          //@ts-ignore
          this.renderMaterials(node, new UntypedFormGroup({}), materialsParent.children);
          return;
        }
        default: {
          sectionFormGroup = this.sectionFormGroups[node.sectionID];
          submission = sectionFormGroup.value;
        }
      }

      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        htmlTemplate,
        submission,
        sectionFormGroup,
        null,
        { hTag, linksPrefix }
      ).then((result: string) => {
        let templDiv = document.createElement('div');
        templDiv.innerHTML = result;

        let xmlFragment = this.ydocService.ydoc.getXmlFragment(node.sectionID);
        let node1 = DOMParser.fromSchema(editor.editorView.state.schema).parse(
          templDiv.firstChild!
        );
        updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
      });
    };
    updateView(articleSection);
    findF(articleSection.children, updateView);
  }
  editNodeChange(nodeId: string) {
    this.applyEditChangeV2(nodeId);
    this.applyEditChange(nodeId);
    this.treeVisibilityChange.next({ action: 'editNode', nodeId });
  }

  async addNodeChange(nodeId: string, originalSectionTemplate: any, callback?: () => void) {
    let newChild = await this.attachChildToNode(nodeId, originalSectionTemplate, undefined);
    this.ydocService.saveSectionMenusAndSchemasDefs([
      getSectionBasicStructure(this.ydocService)(newChild),
    ]);
    this.treeVisibilityChange.next({
      action: 'addNode',
      parentId: nodeId,
      newChild,
      originalSectionTemplate,
    });
    setTimeout(() => {
      callback();
    }, 500);
  }

  renderForms = (sectionToRender: articleSection) => {
    if (sectionToRender.type == 'complex' && sectionToRender.title.name !== '[MM] Materials') {
      sectionToRender.children.forEach((childSection: any) => {
        this.renderForms(childSection);
      });
    }

    let dataFromYMap = this.ydocService.sectionFormGroupsStructures!.get(sectionToRender.sectionID);
    let defaultValues = dataFromYMap ? dataFromYMap.data : sectionToRender!.defaultFormIOValues;
    let sectionContent = defaultValues
      ? this.formBuilderService.populateDefaultValues(
          defaultValues,
          sectionToRender!.formIOSchema,
          sectionToRender!.sectionID,
          sectionToRender
        )
      : sectionToRender!.formIOSchema;

    if (sectionToRender.type == 'complex' && sectionContent.components) {
      this.optionalRequiredWithSubsection(sectionContent, sectionToRender);
    }

    let nodeForm: UntypedFormGroup = new UntypedFormGroup({});
    this.formBuilderService.buildFormGroupFromSchema(nodeForm, sectionContent, sectionToRender!);

    nodeForm.patchValue(defaultValues);
    nodeForm.updateValueAndValidity();
    this.sectionFormGroups[sectionToRender!.sectionID] = nodeForm;
    this.setTitleListener(sectionToRender);
  };

  optionalRequiredWithSubsection(sectionContent, section) {
    const component = sectionContent.components.find(
      (component: any) => component.key == 'sectionContent'
    );

    if (component && component.properties && section) {
      if (
        (component.properties.optional_with_subsections == 'true' ||
          component.properties.optional_with_subsections == true) &&
        (!section.children || section.children.length == 0)
      ) {
        component.validate.required = true;
      } else if (
        (component.properties.optional_with_subsections == 'true' ||
          component.properties.optional_with_subsections == true) &&
        section.children.length > 0
      ) {
        component.validate.required = false;
      }
    }
  }

  async addExternalLinks(linksData: LinksData, section: articleSection) {
    const sectionForm = this.sectionFormGroups[section.sectionID];
    const copySchema = this.formBuilderService.populateDefaultValues(
      linksData,
      section.formIOSchema,
      section.sectionID,
      section,
      sectionForm
    );
    section.defaultFormIOValues = linksData;

    Object.keys(sectionForm.controls).forEach((key) => {
      sectionForm.removeControl(key);
    });

    if (linksData.externalLinks) {
      section.formIOSchema.components[0].defaultValue = copySchema.components[0].defaultValue;
      this.formBuilderService.buildFormGroupFromSchema(sectionForm, section.formIOSchema, section);
    }
    sectionForm.patchValue(linksData);
    sectionForm.updateValueAndValidity();
  }

  addNodeAtPlaceChange(
    parentContainerID: string,
    newSection: any,
    place: any,
    sections?: any[],
    nodeForm?: UntypedFormGroup
  ) {
    if (newSection && !sections) {
      const newNode = this.addNodeAtPlace(parentContainerID, newSection, place);
      this.ydocService.articleSectionsMap.set(newNode.sectionID, newNode);
      this.ydocService.saveSectionMenusAndSchemasDefs([
        getSectionBasicStructure(this.ydocService)(newNode),
      ]);
      this.treeVisibilityChange.next({
        action: 'addNodeAtPlace',
        parentContainerID,
        newSection,
        place,
        newNode,
      });

      return newNode;
    } else if (sections) {
      const newNodes = [];
      const parentSection = this.ydocService.getSectionByID(parentContainerID);
      sections.forEach((sec: any) => {
        const newNode = this.addNodeAtPlace(parentContainerID, sec, place) as articleSection;
        this.ydocService.articleSectionsMap.set(newNode.sectionID, newNode);
        // parentSection.children.push(newNode);
        this.ydocService.saveSectionMenusAndSchemasDefs([
          getSectionBasicStructure(this.ydocService)(newNode),
        ]);
        newNodes.push(newNode);
      });
      parentSection.defaultFormIOValues = newSection.defaultFormIOValues;
      parentSection.mode = 'documentMode';
      this.ydocService.articleSectionsMap.set(parentContainerID, parentSection);
      this.renderMaterials(newSection, nodeForm);
      this.treeVisibilityChange.next({
        action: 'addNodeAtPlace',
        parentContainerID,
        newSection,
        newNode: newNodes,
      });
    }
  }

  addNodeAtPlace(parentContainerID: string, newSection: any, place: any, newNode?: any) {
    if (newNode) {
      if (typeof place == 'string' && place == 'end') {
        if (parentContainerID == 'parentList') {
          this.articleSectionsStructure?.push(getSectionBasicStructure(this.ydocService)(newNode));
        } else {
          let containerToPlaceIn = this.findNodeById(parentContainerID)?.children;
          containerToPlaceIn?.push(getSectionBasicStructure(this.ydocService)(newNode));
        }
      } else if (typeof place == 'number') {
        if (parentContainerID == 'parentList') {
          this.articleSectionsStructure?.splice(
            place,
            0,
            getSectionBasicStructure(this.ydocService)(newNode)
          );
        } else {
          let containerToPlaceIn = this.findNodeById(parentContainerID)?.children;
          containerToPlaceIn?.splice(place, 0, getSectionBasicStructure(this.ydocService)(newNode));
        }
      }
      let buildForms = (node: any) => {
        let dataFromYMap = this.ydocService.sectionFormGroupsStructures!.get(node.sectionID);
        let defaultValues = dataFromYMap ? dataFromYMap.data : node.defaultFormIOValues;
        let sectionContent = defaultValues
          ? this.formBuilderService.populateDefaultValues(
              defaultValues,
              node.formIOSchema,
              node.sectionID,
              node
            )
          : node.formIOSchema;
        let nodeForm: UntypedFormGroup = new UntypedFormGroup({});
        this.formBuilderService.buildFormGroupFromSchema(nodeForm, sectionContent, node);

        nodeForm.patchValue(defaultValues);
        nodeForm.updateValueAndValidity();
        this.sectionFormGroups[node.sectionID] = nodeForm;
        this.setTitleListener(node);

        if (node.children.length > 0) {
          node.children.forEach((child: any) => {
            buildForms(child);
          });
        }
      };
      if (newNode.name != 'Material') {
        buildForms(newNode);
      }
      return;
    }
    let container: articleSection[] = [];

    let sec = renderSectionFunc(newSection, container, this.ydocService.ydoc, this.serviceShare);
    if (sec.title.name !== 'Material') {
      this.renderForms(sec);
    }

    if (typeof place == 'string' && place == 'end') {
      if (parentContainerID == 'parentList') {
        this.articleSectionsStructure?.push(
          getSectionBasicStructure(this.ydocService)(container[0])
        );
      } else {
        let containerToPlaceIn = this.findNodeById(parentContainerID);
        containerToPlaceIn.children.push(getSectionBasicStructure(this.ydocService)(container[0]));
      }
    } else if (typeof place == 'number') {
      if (parentContainerID == 'parentList') {
        this.articleSectionsStructure?.splice(
          place,
          0,
          getSectionBasicStructure(this.ydocService)(container[0])
        );
      } else {
        let containerToPlaceIn = this.findNodeById(parentContainerID)?.children;
        containerToPlaceIn?.splice(
          place,
          0,
          getSectionBasicStructure(this.ydocService)(container[0])
        );
      }
    }
    return container[0];
  }

  updateNodeProsemirrorHtml(newHTML: string, sectionId: string) {
    let nodeRef = this.ydocService.getSectionByID(sectionId)!;
    nodeRef.prosemirrorHTMLNodesTempl = newHTML;
    // this.setArticleSectionStructureFlat()
  }

  deleteNodeChange(nodeId: string, parentId: string) {
    let editorContainer = this.serviceShare.ProsemirrorEditorsService.editorContainers[nodeId];
    if (editorContainer) {
      let doc = editorContainer.editorView.state.doc;
      let docSize = doc.content.size;
      let deletedRefCitations: any[] = [];
      doc.nodesBetween(0, docSize - 2, (node, pos, par, i) => {
        // add logic for deleting citations from ydoc map
        const citationMark = node?.marks.find((mark) => mark.type.name == 'reference_citation');
        if (citationMark) {
          deletedRefCitations.push(JSON.parse(JSON.stringify(citationMark.attrs)));
        }
      });
      if (deletedRefCitations.length > 0) {
        setTimeout(() => {
          this.serviceShare.YjsHistoryService.preventCaptureOfBigNumberOfUpcomingItems();
          this.serviceShare.YjsHistoryService.capturingNewItem = true;
          this.editorsRefsManager!.updateRefsInEndEditorAndTheirCitations();
          setTimeout(() => {
            this.serviceShare.YjsHistoryService.stopBigNumberItemsCapturePrevention();
          }, 30);
        }, 30);
      }
    } else if (this.ydocService.getSectionByID(nodeId).title.name == 'Material') {
      const customPropsObj = this.serviceShare.YdocService.customSectionProps.get('customPropsObj');
      const parent = this.ydocService.getSectionByID(parentId);

      if (customPropsObj[nodeId]) {
        delete customPropsObj[nodeId];
      }

      let { nodeRef, i } = this.deleteNodeById(nodeId);
      parent.children = parent.children.filter((ch: articleSection) => ch.sectionID != nodeId);
      if (parent.children.length == 0) {
        this.showHideSection(parent.sectionID, 'none');
        this.treeVisibilityChange.next({
          action: 'showHideSection',
          sectionID: parent.sectionID,
          value: 'none',
          rerender: true,
        });
      }

      const { data, nodeForm } = this.orderMaterialSections(
        parent.children.map(
          (ch: articleSection) =>
            this.ydocService.getSectionByID(ch.sectionID).originalSectionTemplate
        ),
        new UntypedFormGroup({}),
        customPropsObj
      );
      this.sectionFormGroups[parent.sectionID] = nodeForm;

      this.treeVisibilityChange.next({
        action: 'deleteNode',
        parentId,
        childId: nodeId,
        indexInList: i,
      });
      parent.defaultFormIOValues = data;
      this.renderMaterials(parent, nodeForm);
      return;
    }

    let { nodeRef, i } = this.deleteNodeById(nodeId);
    setTimeout(() => {
      if (nodeRef.custom) {
        let customSectionPropsObj = this.ydocService.customSectionProps.get('customPropsObj');
        if (customSectionPropsObj[nodeId]) {
          customSectionPropsObj[nodeId] = undefined;
        }
        this.ydocService.customSectionProps.set('customPropsObj', customSectionPropsObj);
      }
      this.serviceShare.updateCitableElementsViews();
      // console.log(this.ydocService.articleSectionsMap.toJSON());
      // console.log(this.ydocService.articleStructureMap.toJSON());
    }, 10);
    this.treeVisibilityChange.next({
      action: 'deleteNode',
      parentId,
      childId: nodeId,
      indexInList: i,
    });
  }

  deleteNodeById(id: string) {
    let nodeRef = this.ydocService.articleSectionsMap.get(id);
    let i: number | undefined;
    let arrayRef: basicArticleSection[] | undefined;

    let findF = (list?: basicArticleSection[]) => {
      list?.forEach((node, index, array) => {
        if (node.sectionID !== undefined && node.sectionID == id) {
          i = index;
          arrayRef = array;
        } else if (node.children) {
          findF(node.children);
        }
      });
    };
    findF(this.articleSectionsStructure);
    arrayRef?.splice(i!, 1);

    let deleteNodeData = (node: articleSection) => {
      let id = node.sectionID;
      this.serviceShare.ProsemirrorEditorsService?.deleteEditor(id);
      this.serviceShare.YjsHistoryService?.deleteUndoManager(id);
      this.ydocService.articleStructureMap.delete(id);
    };
    let deleteNodeDataRecursive = (node: articleSection) => {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          deleteNodeDataRecursive(child);
        });
      }
      deleteNodeData(node);
    };
    deleteNodeDataRecursive(nodeRef);
    this.serviceShare.CommentsService.getCommentsInAllEditors();
    return { nodeRef, i };
  }

  findNodeById(id: string) {
    let nodeRef: basicArticleSection | undefined;
    let findF = (list?: basicArticleSection[]) => {
      list?.forEach((node) => {
        if (node.sectionID !== undefined && node.sectionID == id) {
          nodeRef = node;
        } else if (node.children) {
          findF(node.children);
        }
      });
    };
    findF(this.articleSectionsStructure);
    return nodeRef;
  }

  applyNodeDrag(
    from: number,
    to: number,
    prevContainerId: string,
    newContainerId: string,
    materialData?: { parent: articleSection; children: articleSection[] }
  ) {
    this.serviceShare.ProsemirrorEditorsService.editMode = true;
    if (materialData) {
      //@ts-ignore
      this.renderMaterials(materialData.parent, new UntypedFormGroup({}), materialData.children);
    }

    const articleDataCopy = this.articleSectionsStructure!;
    let prevContNewRef: any[];
    let newContNewRef: any[];

    if (newContainerId == 'parentList') {
      newContNewRef = articleDataCopy;
    }

    if (prevContainerId == 'parentList') {
      prevContNewRef = articleDataCopy;
    }

    let findReferences = (container: any) => {
      container.forEach((el: any) => {
        if (el.sectionID == prevContainerId) {
          prevContNewRef = el.children;
        }
        if (el.sectionID == newContainerId) {
          newContNewRef = el.children;
        }
        if (el.children && el.children.length > 0) {
          findReferences(el.children);
        }
      });
    };

    findReferences(articleDataCopy);
    //@ts-ignore
    transferArrayItem(prevContNewRef, newContNewRef, from, to);
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 1000);
  }

  findContainerWhereNodeIs(nodeid: string) {
    let containerofNode: undefined | basicArticleSection[] = undefined;
    let find = (container: basicArticleSection[]) => {
      container.forEach((section) => {
        if (section.sectionID == nodeid) {
          containerofNode = container;
        } else if (section.children.length > 0 && containerofNode == undefined) {
          find(section.children);
        }
      });
    };
    find(this.articleSectionsStructure);
    return containerofNode;
  }

  showDeleteButton(node: articleSection) {
    let r = true;
    let parentNode = this.findParentNodeWithChildID(node.sectionID)!;
    if (parentNode && parentNode !== 'parentNode') {
      r = checkIfSectionsAreUnderOrAtMin(node, parentNode);
    } else if (parentNode == 'parentNode') {
      r = checkIfSectionsAreUnderOrAtMinAtParentList(
        this.articleSectionsStructure,
        node,
        this.parentListRules,
        this.serviceShare
      );
    }
    return r;
  }

  checkIfNodeIsAtMaxInParentListWithBESection(data: any) {
    return checkIfSectionsAreAboveOrAtMaxAtParentListWithName(
      this.articleSectionsStructure,
      data,
      this.parentListRules,
      this.serviceShare
    );
  }

  checkIfCanMoveNodeOutOfParentList(node: articleSection) {
    return checkIfSectionsAreUnderOrAtMinAtParentList(
      this.articleSectionsStructure,
      node,
      this.parentListRules,
      this.serviceShare
    );
  }

  checkIfCanMoveNodeInParentList(node: articleSection) {
    return checkIfSectionsAreAboveOrAtMaxAtParentList(
      this.articleSectionsStructure,
      node,
      this.parentListRules,
      this.serviceShare
    );
  }

  showAddBtn(node: articleSection) {
    let r = true;
    let parentNode = this.findParentNodeWithChildID(node.sectionID)!;
    if (parentNode && parentNode !== 'parentNode') {
      r = checkIfSectionsAreAboveOrAtMax(node, parentNode);
    } else if (parentNode == 'parentNode') {
      r = checkIfSectionsAreAboveOrAtMaxAtParentList(
        this.articleSectionsStructure,
        node,
        this.parentListRules,
        this.serviceShare
      );
    }
    r = node.title.name == 'Material' ? false : r;
    return r;
  }

  showAddSubsectionBtn(node: basicArticleSection) {
    let fileredSections = getFilteredSectionChooseData(node, this);
    return this.getNodeLevel(node).nodeLevel + 1 < 4 && fileredSections.length > 0;
  }

  findParentNodeWithChildID(nodeid: string) {
    let parent: undefined | basicArticleSection | 'parentNode' = undefined;
    let find = (
      container: basicArticleSection[],
      parentNode: basicArticleSection | 'parentNode'
    ) => {
      container.forEach((section) => {
        if (section.sectionID == nodeid) {
          parent = parentNode;
        } else if (section.children.length > 0 && parent == undefined) {
          find(section.children, section);
        }
      });
    };
    find(this.articleSectionsStructure, 'parentNode');
    return parent == 'parentNode'
      ? 'parentNode'
      : this.ydocService.articleSectionsMap.get(parent?.sectionID);
  }

  async attachChildToNode(clickedNode: string, originalSectionTemplate: any, node: any) {
    let newNodeContainer = this.findContainerWhereNodeIs(clickedNode);
    let nodeRef = this.ydocService.getSectionByID(clickedNode);
    if (node) {
      newNodeContainer.splice(
        newNodeContainer.findIndex((s) => s.sectionID == nodeRef.sectionID)! + 1,
        0,
        getSectionBasicStructure(this.ydocService)(node)
      );
      let buildForms = (node: any) => {
        let dataFromYMap = this.ydocService.sectionFormGroupsStructures!.get(node.sectionID);
        let defaultValues = dataFromYMap ? dataFromYMap.data : node.defaultFormIOValues;
        let sectionContent = defaultValues
          ? this.formBuilderService.populateDefaultValues(
              defaultValues,
              node.formIOSchema,
              node.sectionID,
              node
            )
          : node.formIOSchema;
        let nodeForm: UntypedFormGroup = new UntypedFormGroup({});
        this.formBuilderService.buildFormGroupFromSchema(nodeForm, sectionContent, node);

        nodeForm.patchValue(defaultValues);
        nodeForm.updateValueAndValidity();
        this.sectionFormGroups[node.sectionID] = nodeForm;
        this.setTitleListener(node);
        if (node.children.length > 0) {
          node.children.forEach((child: any) => {
            buildForms(child);
          });
        }
      };
      buildForms(node);
      return;
    }
    let newChild: articleSection;
    let container: any[] = [];
    let newSec = renderSectionFunc(
      originalSectionTemplate,
      container,
      this.ydocService.ydoc,
      this.serviceShare
    );
    this.renderForms(newSec);
    newChild = container[0];
    newNodeContainer.splice(
      newNodeContainer.findIndex((s) => s.sectionID == nodeRef.sectionID)! + 1,
      0,
      getSectionBasicStructure(this.ydocService)(newChild)
    );
    this.ydocService.articleSectionsMap.set(newChild.sectionID, newChild);
    return Promise.resolve(newChild);
  }

  applyEditChangeV2(id: string) {
    let nodeRef = this.ydocService.getSectionByID(id)!;
    let {
      sectionMenusAndSchemaDefsFromJSON,
      formIOJSON,
      sectionMenusAndSchemasDefsfromJSONByfieldsTags,
    } = parseSecFormIOJSONMenuAndSchemaDefs(nodeRef.formIOSchema, {
      menusL: 'customSectionJSONMenuType',
      tagsL: 'customSectionJSONAllowedTags',
    });
    this.serviceShare.ProsemirrorEditorsService.globalMenusAndSchemasSectionsDefs[id] =
      sectionMenusAndSchemasDefsfromJSONByfieldsTags;
  }

  applyEditChange(id: string) {
    let nodeRef = this.findNodeById(id)!;
    if (nodeRef && !nodeRef.active) {
      nodeRef.active = true;
    }
  }

  async applyTaxonImportChange(addedTaxonSections: articleSection[], parentID: string) {
    addedTaxonSections.forEach((section) => {
      this.addNodeAtPlace(parentID, section.originalSectionTemplate, 'end', section);
    });
    this.setArticleSectionStructureFlat();
  }

  async renderTaxons(section: articleSection, data: any): Promise<void> {
    const htmlTemplate = section.prosemirrorHTMLNodesTempl;
    let formGroup = this.sectionFormGroups[section.sectionID];
    let editorContainer =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[section.sectionID];
    const { taxonTitle, label } = this.generateTaxonTitle(data);

    data.taxonTitle = taxonTitle;
    if (label) {
      data.label = label;
    }

    formGroup.patchValue(data);

    if (editorContainer) {
      const result = await this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        htmlTemplate,
        data,
        formGroup,
        null
      );
      const templDiv = document.createElement('div');
      templDiv.innerHTML = result;

      const xmlFragment = this.ydocService.ydoc.getXmlFragment(section.sectionID);
      const node1 = DOMParser.fromSchema(editorContainer.editorView.state.schema).parse(
        templDiv.firstChild!
      );
      updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
    }
  }

  async renderExternalLinks(section: articleSection, linksData: any) {
    const htmlTemplate = section.prosemirrorHTMLNodesTempl;

    const copySchema = this.formBuilderService.populateDefaultValues(
      { externalLinks: linksData },
      section.formIOSchema,
      section.sectionID,
      section
    );
    section.formIOSchema.components[0].defaultValue = copySchema.components[0].defaultValue;
    const formGroup = this.formBuilderService.buildFormGroupFromSchema(
      new UntypedFormGroup({}),
      section.formIOSchema,
      section
    );

    this.sectionFormGroups[section.sectionID] = formGroup;
    let editorContainer =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[section.sectionID];

    if (editorContainer) {
      const result = await this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        htmlTemplate,
        linksData,
        formGroup,
        section.title.name.replace(/[\W_]+/g, ''),
        { linksPrefix: copySchema?.linksPrefix }
      );

      const templDiv = document.createElement('div');
      templDiv.innerHTML = result;

      const xmlFragment = this.serviceShare.YdocService.ydoc.getXmlFragment(section.sectionID);
      const node1 = DOMParser.fromSchema(editorContainer.editorView.state.schema).parse(
        templDiv.firstChild!
      );
      updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
      this.showHideSection(section.sectionID, 'block');
    }
  }

  // async applyTaxonImportChange(addedTaxonSections: articleSection[], parentID: string) {
  //   addedTaxonSections.forEach((section) => {
  //     this.addNodeAtPlace(parentID, section.originalSectionTemplate, "end", section);
  //   })
  //   this.setArticleSectionStructureFlat();
  // }

  // async renderTaxons(section: articleSection, data: any): Promise<void> {
  //   const htmlTemplate = section.prosemirrorHTMLNodesTempl;
  //   let formGroup = this.sectionFormGroups[section.sectionID];
  //   let editorContainer = this.serviceShare.ProsemirrorEditorsService.editorContainers[section.sectionID];
  //   const {taxonTitle, label} = this.generateTaxonTitle(data);

  //   data.taxonTitle = taxonTitle;
  //   if(label) {
  //     data.label = label;
  //   }

  //   formGroup.patchValue(data);

  //   if (editorContainer) {
  //     const result = await this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(htmlTemplate, data, formGroup, null);
  //     const templDiv = document.createElement('div');
  //     templDiv.innerHTML = result;

  //     const xmlFragment = this.ydocService.ydoc.getXmlFragment(section.sectionID);
  //     const node1 = DOMParser.fromSchema(editorContainer.editorView.state.schema).parse(templDiv.firstChild!);
  //     updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
  //   }
  // }

  // async renderExternalLinks(section: articleSection, linksData: any) {
  //   const htmlTemplate = section.prosemirrorHTMLNodesTempl;

  //   const copySchema = this.formBuilderService.populateDefaultValues({ externalLinks: linksData }, section.formIOSchema, section.sectionID, section);
  //   section.formIOSchema.components[0].defaultValue = copySchema.components[0].defaultValue;
  //   const formGroup = this.formBuilderService.buildFormGroupFromSchema(new FormGroup({}), section.formIOSchema, section);

  //   this.sectionFormGroups[section.sectionID] = formGroup;
  //   let editorContainer = this.serviceShare.ProsemirrorEditorsService.editorContainers[section.sectionID];

  //   if (editorContainer) {
  //     const result = await this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(htmlTemplate, linksData, formGroup, section.title.name.replace(/[\W_]+/g, ''), { linksPrefix: copySchema?.linksPrefix });

  //     const templDiv = document.createElement('div');
  //     templDiv.innerHTML = result;

  //     const xmlFragment = this.serviceShare.YdocService.ydoc.getXmlFragment(section.sectionID);
  //     const node1 = DOMParser.fromSchema(editorContainer.editorView.state.schema).parse(templDiv.firstChild!);
  //     updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
  //     this.showHideSection(section.sectionID, "block");
  //   }
  // }

  renderMaterials(
    materialSection: articleSection,
    formGroup: UntypedFormGroup = new UntypedFormGroup({}),
    children?: basicArticleSection[]
  ) {
    if (children) {
      const { data, nodeForm } = this.orderMaterialSections(
        children.map(
          (ch: basicArticleSection) =>
            this.ydocService.getSectionByID(ch.sectionID).originalSectionTemplate
        ),
        formGroup
      );
      this.sectionFormGroups[materialSection.sectionID] = nodeForm;
      materialSection.defaultFormIOValues = data;
      formGroup = nodeForm;
    }
    const htmlTemplate = materialSection.prosemirrorHTMLNodesTempl;
    let editorContainer =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[materialSection.sectionID];

    if (editorContainer) {
      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        htmlTemplate,
        materialSection.defaultFormIOValues,
        formGroup,
        null,
        { materials: true }
      ).then((result: string) => {
        const templDiv = document.createElement('div');
        templDiv.innerHTML = result;

        const xmlFragment = this.ydocService.ydoc.getXmlFragment(materialSection.sectionID);
        const node1 = DOMParser.fromSchema(editorContainer.editorView.state.schema).parse(
          templDiv.firstChild!
        );
        updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
        this.setArticleSectionStructureFlat(materialSection, children);
      });
    }
  }

  orderMaterialSections(sections: any[], nodeForm: UntypedFormGroup, customPropsObj?: any) {
    const propsObj = customPropsObj
      ? customPropsObj
      : this.serviceShare.YdocService.customSectionProps.get('customPropsObj');
    const newOrderedSections: any[] = [];
    const data = {};
    const orderedMat: { [key: string]: any[] } = {};
    const order: string[] = [];
    const unorderedMat: articleSection[] = [];
    sections.forEach((materialSec) => {
      const matType = materialSec.defaultFormIOValues.typeStatus;
      if (matType) {
        if (!order.includes(matType)) {
          order.push(matType);
          orderedMat[matType] = [];
        }
        orderedMat[matType].push(materialSec);
      } else {
        unorderedMat.push(materialSec);
      }
    });
    order.forEach((type, i) => {
      orderedMat[type].forEach((materialSec, j, arr) => {
        if (j == 0) {
          if (arr.length > 1) {
            materialSec.defaultFormIOValues.typeHeading = type + 's';
          } else {
            materialSec.defaultFormIOValues.typeHeading = type;
          }
        } else {
          materialSec.defaultFormIOValues.typeHeading = undefined;
        }
        materialSec.defaultFormIOValues['sectionID'] = materialSec.sectionID;
        if (data[materialSec.defaultFormIOValues.typeStatus]) {
          data[materialSec.defaultFormIOValues.typeStatus].push(materialSec.defaultFormIOValues);
        } else {
          data[materialSec.defaultFormIOValues.typeStatus] = [materialSec.defaultFormIOValues];
        }

        propsObj[materialSec.sectionID] = materialSec.defaultFormIOValues;
        newOrderedSections.push(materialSec);
      });
    });

    sections.forEach((section) => {
      let defaultValues = section.defaultFormIOValues;

      Object.keys(defaultValues).forEach((key) => {
        nodeForm.addControl(
          key + '&' + section.sectionID,
          new UntypedFormControl(defaultValues[key])
        );
      });
    });

    this.serviceShare.YdocService.customSectionProps.set('customPropsObj', propsObj);
    return { data, nodeForm };
  }

  editMaterial(section: articleSection, submission: any) {
    const node = this.findNodeById(section.sectionID);
    node.label = submission.typeStatus;
    const parent = this.ydocService.getSectionByID(section.originalSectionTemplate.parentId);
    const originalTemplates = [];
    parent.children = parent.children.map((ch: articleSection) => {
      const child = this.ydocService.getSectionByID(ch.sectionID);
      if (child.sectionID == node.sectionID) {
        child.originalSectionTemplate.defaultFormIOValues = submission;
        child.defaultFormIOValues = submission;
        child.title.label = submission.typeStatus;
        this.ydocService.articleSectionsMap.set(section.sectionID, child);
      }
      originalTemplates.push(child.originalSectionTemplate);
      return child;
    });
    const { data, nodeForm } = this.orderMaterialSections(
      originalTemplates,
      new UntypedFormGroup({})
    );
    this.sectionFormGroups[parent.sectionID] = nodeForm;
    parent.defaultFormIOValues = data;
    this.ydocService.articleSectionsMap.set(parent.sectionID, parent);

    this.renderMaterials(parent, nodeForm);
  }

  editMultipleMaterials(materials: articleSection[], parentID: string) {
    const parent = this.ydocService.getSectionByID(parentID);
    const originalTemplates = [];
    materials.forEach((materialSec) => {
      const node = this.findNodeById(materialSec.sectionID);
      node.label = materialSec.originalSectionTemplate.defaultFormIOValues.typeStatus;
      originalTemplates.push(materialSec.originalSectionTemplate);
    });
    parent.children = materials;
    const { data, nodeForm } = this.orderMaterialSections(
      originalTemplates,
      new UntypedFormGroup({})
    );

    this.sectionFormGroups[parent.sectionID] = nodeForm;
    parent.defaultFormIOValues = data;
    this.ydocService.articleSectionsMap.set(parent.sectionID, parent);

    this.renderMaterials(parent, nodeForm);
  }

  deleteAllCheckedMaterials(materialSection: articleSection, checked: any[]) {
    materialSection.children.forEach((nodeRef) => {
      if (checked.find((m: any) => m.sectionID == nodeRef.sectionID)) {
        this.deleteNodeChange(nodeRef.sectionID, materialSection.sectionID);
      }
    });
  }

  generateTaxonTitle(data: any) {
    const title = [];
    let label: string;
    // typeoftreatment: this.typeoftreatment!.value,
    // otherTreatmentType: this.otherTreatmentType.value,
    if (data?.rank) {
      if (
        data.rank != 'form' &&
        data.rank != 'variety' &&
        data.rank != 'subspecies' &&
        data.rank != 'species'
      ) {
        label = data.rank[0].toLocaleUpperCase() + (data.rank as string).substring(1);
        title.push(label);
      }
      if (data.rank === 'class') {
        title.push(data.class);
      }
      if (data.genus) {
        title.push(data.genus);
      }
      if (data.subgenus) {
        title.push(`(${data.subgenus})`);
      }
      if (data.species) {
        title.push(data.species);
      }
      if (data.rank === 'variety') {
        title.push(`var. ${data.variety}`);
      }
      if (data.rank === 'form') {
        title.push(`f. ${data.form}`);
      }
      if (data.rank === 'subspecies') {
        title.push('subsp. ' + (data.subspecies || ''));
      }
      title.push(data.authorandyear);
      // if(data.rank === 'genus' && data.typeoftreatment === 'New taxon') {
      //   title.push('gen., n.')
      // }
      if (data.typeoftreatment != 'observation/description') {
        if (data.typeoftreatment == 'other') {
          title.push(data.otherTreatmentType);
        } else {
          title.push(data.typeoftreatment);
        }
      }
    }

    return { taxonTitle: title.join(' '), label };
  }

  showHideSection(sectionId: string, action: 'none' | 'block') {
    const editor = document.getElementsByClassName(sectionId)[0] as HTMLElement;
    if (editor) {
      editor.style.display = action;
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as PModel from 'prosemirror-model';
import { schema } from '@app/editor/utils/Schema';
import { articleSection } from '@app/editor/utils/interfaces/articleSection';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { filterFieldsValues } from '@app/editor/utils/fieldsMenusAndScemasFns';
import {
  Columns,
  Coordinates,
  CuratorialUnit,
  DataPaperSpecificSectionsSubmissionFormData,
  DataSetColumnsData,
  SpecimenPreservationMethod,
  Taxa,
  TaxonomicCoverage,
  TemporalCoverage,
  TemporalCoverageData,
  UsageLicence,
} from '@app/editor/utils/interfaces/dataPaperSpecificSections';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { FormBuilderService } from '@app/editor/services/form-builder.service';

@Injectable({
  providedIn: 'root',
})
export class DataPaperService {
  constructor(
    private serviceShare: ServiceShare,
    private formBuilderService: FormBuilderService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  coordinates: Coordinates;
  taxonomicCoverage: TaxonomicCoverage;
  temporalCoverage: TemporalCoverage;
  temporalCoverageData: TemporalCoverageData;
  usageLicence: UsageLicence;
  columns: Columns;
  dataSetColumnsData: DataSetColumnsData;
  specimenPreservationMethod: SpecimenPreservationMethod;
  curatorialUnit: CuratorialUnit;

  dataPaperSpecificSections = [
    '[PS] Coordinates',
    '[PS] Taxa included',
    '[PS] Temporal coverage',
    '[PS] Usage licence',
    '[PS] Data set description',
    '[PS] Specimen preservation method',
    '[PS] Curatorial unit',
  ];

  addCustomSectionData(section: articleSection, data: any) {
    let customPropsObj = this.serviceShare.YdocService.customSectionProps?.get('customPropsObj');
    customPropsObj[section.sectionID] = data;
    this.serviceShare.YdocService.customSectionProps?.set('customPropsObj', customPropsObj);
  }

  parseNamedContentFromJats(element: Element, data: any) {
    Array.from(element.children).forEach((el) => {
      if (el.nodeName == 'named-content' && el.attributes.getNamedItem('content-type').value) {
        let contentType = el.attributes.getNamedItem('content-type').value;

        data[contentType] = el.textContent;
      }
    });

    return data;
  }

  extractTemporalCoverageDataFromJats(element: Element, temporalCoverageTypeOptions: string[]) {
    const parseTemporalCoverage = (el: Element) => {
      const foundType = temporalCoverageTypeOptions.find((value) => el.textContent.includes(value));

      const type = foundType ? foundType : '';

      const rowData: TemporalCoverage = {
        type: type,
        formationPeriod: '',
        livingTimePeriod: '',
        singleDateTime: '',
        beginDate: '',
        endDate: '',
      };

      this.temporalCoverage = this.parseNamedContentFromJats(el, rowData);
      this.temporalCoverageData.temporalCoverage.push(this.temporalCoverage);
    };

    Array.from(element.children).forEach((el) => {
      if (el.nodeName == 'p') parseTemporalCoverage(el);
    });
  }

  extractTaxonomicCoverageDataFromJats(element: Element) {
    const parseTaxaContent = (tableEl: Element) => {
      const rows = tableEl.querySelectorAll('tbody tr');

      rows.forEach((row, index) => {
        if (index === 0) return; // Skip the header row

        const rowData: Taxa = {
          taxonRankName: '',
          taxonRankValue: '',
          commonName: '',
        };

        const cells = row.querySelectorAll('td');

        cells.forEach((cell) => {
          this.parseNamedContentFromJats(cell, rowData);
        });

        this.taxonomicCoverage.taxonomicCoverage.push(rowData);
      });
    };

    Array.from(element.children).forEach((el) => {
      if (el.nodeName == 'table-wrap') parseTaxaContent(el);
    });
  }

  extractColumnsDataFromJats(element: Element) {
    const parseColumnsContent = (tableEl: Element) => {
      const rows = tableEl.querySelectorAll('tbody tr');

      rows.forEach((row, index) => {
        if (index === 0) return; // Skip the header row

        const cells = row.querySelectorAll('td');

        const rowData: Columns = {
          columnLabel: cells[0].textContent.trim() || '',
          columnDescription: cells[1].textContent.trim() || '',
        };

        this.dataSetColumnsData.columns.push(rowData);
      });
    };

    Array.from(element.children).forEach((el) => {
      if (el.nodeName == 'p') {
        this.dataSetColumnsData.sectionContent = el.textContent || '';
      } else if (el.nodeName == 'table-wrap') {
        parseColumnsContent(el);
      }
    });
  }

  extractSpecimenPreservationMethodDataFromJats(element: Element) {
    const formatString = (text: string) => {
      if (text == 'freezeDried') return 'Freeze-dried';

      // Add space before each uppercase letter, and convert the entire string to lowercase.
      const formattedString = text
        .replace(/([A-Z0-9]+)/g, ' $1')
        .toLowerCase()
        .trim();

      // Capitalize the first letter of the string and concatenate with the rest of the string.
      return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    };

    Array.from(element.children).forEach((el) => {
      if (el.nodeName == 'p') {
        let specimenPreservationMethod = el.textContent ? formatString(el.textContent) : '';

        this.specimenPreservationMethod.specimenPreservationMethod = specimenPreservationMethod;
      }
    });
  }

  prepareSectionForm(
    data: DataPaperSpecificSectionsSubmissionFormData,
    articleSection: articleSection,
    hasEditGrid = false
  ): UntypedFormGroup {
    const sectionForm = new UntypedFormGroup({});

    filterFieldsValues(
      articleSection.formIOSchema,
      { data: data },
      this.serviceShare,
      articleSection.sectionID,
      true,
      '',
      false
    );

    this.serviceShare.YdocService.sectionFormGroupsStructures!.set(articleSection.sectionID, {
      data: data,
      updatedFrom: this.serviceShare.YdocService.ydoc?.guid,
    });

    const copySchema = this.formBuilderService.populateDefaultValues(
      data,
      articleSection.formIOSchema,
      articleSection.sectionID,
      articleSection,
      sectionForm
    );

    if (hasEditGrid) {
      copySchema.components.forEach((component: any, index: number) => {
        articleSection.formIOSchema.components[index].defaultValue = component.defaultValue;
      });
    }

    this.formBuilderService.buildFormGroupFromSchema(
      sectionForm,
      articleSection.formIOSchema,
      articleSection
    );

    sectionForm.patchValue(data);
    sectionForm.updateValueAndValidity();
    this.addCustomSectionData(articleSection, data);

    if (hasEditGrid) {
      this.serviceShare.TreeService.sectionFormGroups[articleSection.sectionID] = sectionForm;
    }

    return sectionForm;
  }

  renderSectionNode(
    htmlTemplate: string,
    data: any,
    sectionForm: UntypedFormGroup,
    callback: (node: PModel.Node) => void
  ) {
    this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
      htmlTemplate,
      data,
      sectionForm,
      null
    ).then((result: string) => {
      const templDiv = document.createElement('div');
      templDiv.innerHTML = result;
      const node = PModel.DOMParser.fromSchema(schema).parse(templDiv.firstChild);
      callback(node);
    });
  }

  parseDataPaperSpecificSectionsFromJats(
    sections: Element[],
    articleSection: articleSection,
    callback: (node: PModel.Node) => void
  ) {
    switch (articleSection.title.name) {
      case '[PS] Coordinates': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('Coordinates')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.coordinates = {
          eastBoundingCoordinate: '',
          westBoundingCoordinate: '',
          southBoundingCoordinate: '',
          northBoundingCoordinate: '',
        };

        Array.from(element.children).forEach((el) => {
          if (el.nodeName == 'p') this.parseNamedContentFromJats(el, this.coordinates);
        });

        const sectionForm = this.prepareSectionForm(this.coordinates, articleSection);
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.coordinates,
          sectionForm,
          callback
        );

        break;
      }
      case '[PS] Usage licence': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('Usage licence')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.usageLicence = { usageLicence: '' };
        const usageLicenceOptions = articleSection.formIOSchema.components[0].data.values.map(
          (option: any) => option.value
        );

        Array.from(element.children).forEach((el) => {
          if (el.nodeName == 'p') {
            let licenceText = el.textContent || '';
            this.usageLicence.usageLicence = usageLicenceOptions.includes(licenceText)
              ? licenceText
              : 'Other';
          }
        });

        const sectionForm = this.prepareSectionForm(this.usageLicence, articleSection);
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.usageLicence,
          sectionForm,
          callback
        );

        break;
      }
      case '[PS] Taxa included': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('Taxa included')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.taxonomicCoverage = { taxonomicCoverage: [] };

        this.extractTaxonomicCoverageDataFromJats(element);
        const sectionForm = this.prepareSectionForm(this.taxonomicCoverage, articleSection, true);
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.taxonomicCoverage,
          sectionForm,
          callback
        );

        break;
      }
      case '[PS] Temporal coverage': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('Temporal coverage')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.temporalCoverageData = { temporalCoverage: [] };

        const temporalCoverageTypeOptions =
          articleSection.formIOSchema.components[0].components[0].data.values.map(
            (option: any) => option.value
          );
        this.extractTemporalCoverageDataFromJats(element, temporalCoverageTypeOptions);

        const sectionForm = this.prepareSectionForm(
          this.temporalCoverageData,
          articleSection,
          true
        );
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.temporalCoverageData,
          sectionForm,
          callback
        );

        break;
      }
      case '[PS] Data set description': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('description')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.dataSetColumnsData = {
          sectionContent: '',
          columns: [],
        };

        this.extractColumnsDataFromJats(element);

        const sectionForm = this.prepareSectionForm(this.dataSetColumnsData, articleSection, true);
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.dataSetColumnsData,
          sectionForm,
          callback
        );

        break;
      }
      case '[PS] Specimen preservation method': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('Specimen preservation method')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.specimenPreservationMethod = { specimenPreservationMethod: '' };

        this.extractSpecimenPreservationMethodDataFromJats(element);
        const sectionForm = this.prepareSectionForm(
          this.specimenPreservationMethod,
          articleSection
        );
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.specimenPreservationMethod,
          sectionForm,
          callback
        );

        break;
      }
      case '[PS] Curatorial unit': {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'sec' &&
            el.attributes.getNamedItem('sec-type').value.includes('Curatorial unit')
        );

        if (!element) callback(schema.nodes.doc.create({}));

        this.curatorialUnit = {
          methodType: 'Count Range',
          beginRange: '',
          endRange: '',
          unitType: '',
        };

        Array.from(element.children).forEach((el) => {
          if (el.nodeName == 'p') this.parseNamedContentFromJats(el, this.curatorialUnit);
        });

        const sectionForm = this.prepareSectionForm(this.curatorialUnit, articleSection);
        this.renderSectionNode(
          articleSection.prosemirrorHTMLNodesTempl,
          this.curatorialUnit,
          sectionForm,
          callback
        );

        break;
      }
    }
  }

  convertToJats(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    // Endpoint to transform EML XML to JATS XML
    return this.serviceShare.httpClient.post(
      `${this.config.apiGatewayService}/api/jats/eml/jats`,
      formData,
      { responseType: 'text' }
    );
  }
}
